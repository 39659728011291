const userLinkRole ="/user/userLinkRole";
const page ="/user/page";
const del ="/user/delete";
const roleSelect ="/role/roleSelect";  
const add ="/user/add";  
const editPassWord="/user/editPassWord";
const bindPowerIds ="/power/menu/bindPowerIds";  
const linkServer ="/user/linkServer";  
const getUserLinkServer ="/user/getUserLinkServer";  

export default {
    userLinkRole,page,del,roleSelect,add,editPassWord,bindPowerIds,linkServer,getUserLinkServer
  }