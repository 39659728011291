<template>
  <div class="main">

    <div class="left" style="background-color:#F2F6FC">
      <div class=logo :style="{ backgroundColor: skin }">
        <span style="margin-left:10px">
          <img width="40" height="40" src="../assets/images/top.png" />
        </span>
        <span v-if="!isCollapse" style="margin-left:10px;font-size: 11px;">MHP人工智能实验室</span>
      </div>
      <div>
        <el-menu default-active="0" :collapse="isCollapse" :style="{ width: (!isCollapse ? '200px' : 'auto') }"
          text-color="black" active-text-color="#ffd04b" router style="background-color:#F2F6FC;">
          <el-submenu :index="index + 1 + ''" v-for="(item, index) in menuList" :key="item.id" :hidden="item.id == 0">
            <template slot="title">
              <i :class="item.icon"></i>
              <span style="width: 100%;" @click="fristMenuClick(item.menuName)">{{ item.menuName }}</span>
            </template>
            <div v-for="child in item.childList" :key="child.meunId">
              <el-menu-item :index="child.path" @click="secendMenuClick(child, item.menuName, child.menuName)">
                {{ child.menuName }}</el-menu-item>
            </div>
          </el-submenu>
        </el-menu>
      </div>

    </div>
    <!-- 右边布局 -->
    <div class="right">
      <!-- 右上 -->
      <div class="nav" :style="{ backgroundColor: skin }">
        <!-- 上左 -->
        <div class="nav-left">
          <span style="margin-top:8px;"><i :class="(!isCollapse ? 'el-icon-s-fold' : 'el-icon-s-unfold')"
              @click="isCollapse = !isCollapse"></i></span>
          <div>
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
              <el-breadcrumb-item :hidden="fristTop == ''"><a href="/layout">{{ fristTop }}</a></el-breadcrumb-item>
              <el-breadcrumb-item :hidden="secendTop == ''">{{ secendTop }}</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
        </div>
        <!-- 上右 -->
        <div class="nav-right">
          <el-menu :default-active="activeIndex2" class="el-menu-demo" mode="horizontal" :background-color="skin"
            text-color="#fff" active-text-color="#ffd04b">
            <el-menu-item index="1">
              消息中心
              <el-badge style="margin-top: -25px;" :value="messageValue" v-if="messageValue > 0" :max="99">
              </el-badge>
            </el-menu-item>
            <el-submenu index="2">
              <template slot="title">换肤</template>
              <el-menu-item v-for="(item, index) in theme" :key="index" @click="changeTheme(item.color)">{{ item.name }}
              </el-menu-item>
              <el-menu-item>
                <span class="demonstration">自定义</span>
                <el-color-picker size="mini" style="margin-left:30px;background:red" v-model="skin"
                  @change="changeTheme(null)"></el-color-picker>
              </el-menu-item>
            </el-submenu>

            <el-submenu index="3">
              <template slot="title">用户管理</template>
              <el-menu-item index="3-1" @click="dialogFormVisible = true">修改密码</el-menu-item>
              <el-menu-item index="3-1" @click="clearSession">清理菜单</el-menu-item>
              <el-menu-item index="3-2" @click="loginOut">退出</el-menu-item>
            </el-submenu>
          </el-menu>
        </div>
      </div>
      <!-- 右下 -->
      <div class="center">

        <el-tag style="margin: 5px 0px 30px 5px;cursor: pointer " v-for="tag in tabs" :key="tag.meunId" effect="light"
          :closable="tag.meunId != 0" :type="tag.type" @click="tabClick(tag)" size="medium" 
          @close="handleClose(tag)">
          {{ tag.title }}
        </el-tag>
        <router-view :key="activeTab"></router-view>

      </div>
    </div>

    <el-dialog title="修改密码" :visible.sync="dialogFormVisible" width="25%">
      <el-form :model="form" :rules="rules" ref="from">
        <el-form-item label="新密码" :label-width="formLabelWidth" prop="newPassWord">
          <el-input v-model="form.newPassWord" autocomplete="off" type="passWord"></el-input>n
        </el-form-item>
        <el-form-item label="确认密码" :label-width="formLabelWidth" prop="oldPassWord">
          <el-input v-model="form.oldPassWord" autocomplete="off" type="passWord"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="editPassWord('from')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>

import { webSocketUrl } from "@/util/request.js";
import { webSocketInfo } from "@/util/common.js";
import { mapState } from "vuex";
import userApi from "@/api/user.js";


export default {
  data() {
    return {
      tabs: [{ title: '首页', path: '/home', meunId: 0, type: "" }],
      activeTab: 'mhp-首页',
      userId: localStorage.getItem("userId"),
      phone: localStorage.getItem("phone"),
      activeIndex2: "1",
      menuList: null,
      skin: localStorage.getItem("skin") || "#493131", //皮肤默认颜色
      isCollapse: false,
      fristTop: "",
      secendTop: "",
      messageValue: 0,
      dialogFormVisible: false,
      form: { newPassWord: "", oldPassWord: "" },
      formLabelWidth: '100px',
      rules: {
        newPassWord: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          {
            min: 6,
            max: 20,
            message: "长度在 6 到 20 个字符",
            trigger: "blur",
          }

        ],
        oldPassWord: [
          { required: true, message: "请确认新密码", trigger: "blur" },
          {
            min: 6,
            max: 20,
            message: "长度在 6 到 20 个字符",
            trigger: "blur",
          }]
      }
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {
    //验证用户登录
    this.verifyUser();
    //获取菜单
    this.getMenu();
    if (localStorage.getItem("tabsCache")) {
      this.tabs = JSON.parse(localStorage.getItem("tabsCache"));
      this.activeTab = localStorage.getItem("tabsIndexCache");
    }



  },
  computed: {
    ...mapState("theme", ["theme"])
  },
  mounted() {
    webSocketInfo(webSocketUrl);
  },

  //生命周期 - 挂载完成（访问DOM元素）
  methods: {
    tabClick(tab) {

      this.tabFoucos(tab.path)

      if (tab.title == "首页") {
        this.skipHome();
      } else {

        let url = "/" + tab.path + "?meunId=" + tab.meunId;
        this.setTabCache();
        this.$router.push(url); // tab.path 是你要导航到的页面的路由路径
      }
    },
    handleClose(tag) {
      if (tag.title == "首页") {
        return;
      }
      let index = this.tabs.indexOf(tag)
      this.tabs.splice(index, 1);
      if (index == this.tabs.length) {
        this.tabClick(this.tabs[index - 1])
      }
      this.setTabCache();
    },
    addTab(route) {
      const path = route.path;
      this.tabFoucos(path)
      let row = this.tabs.filter(f => f.path == path)
      if (row.length > 0) {
        return;
      }

      this.tabs.push({ title: route.menuName, path: path, meunId: route.id, type: 'success' });
      this.setTabCache();
    },
    tabFoucos(path) {
      this.tabs.forEach(f => {
        f.type = ''
        if (f.path == path) {
          f.type = 'success'
        }
      })
    },
    skipHome() {
      let arr = this.menuList.filter(f => f.menuName == '首页');
      console.log(arr)
      if (arr.length > 0) {
        this.$router.push("/home");
      } else {
        try {
          let url = "/welcome";
          this.$router.push(url);

        } catch (error) {
          this.$router.push("/home");
        }
      }
    },
    setTabCache() {
      localStorage.setItem("tabsCache", JSON.stringify(this.tabs));
      localStorage.setItem("tabsIndexCache", this.activeTab);
    },
    //websocket

    getBrowserId() {
      if (window.localStorage) {
        var id = localStorage.getItem("browserId");
        if (!id) {
          id = Math.random().toString(36).substring(2);
          localStorage.setItem("browserId", id);
        }
        return id;
      }
    },
    //切换皮肤
    changeTheme(color) {
      if (color != null) {
        this.skin = color;
      }
      //设置默认皮肤
      localStorage.setItem("skin", this.skin);
    },
    //获取权限
    getMenu() {
      //获取默认皮肤
      this.$get("/user/getUserMenu", {}).then((res) => {
        if (res.success && res.statusCode == 200) {
          this.menuList = res.data;
        }
      });
    },
    editPassWord(ruleForm) {
      this.dialogFormVisible = true;
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          if (this.form.newPassWord != this.form.oldPassWord) {
            this.$msg_err("您输入的两次密码不一致，请确认");
          } else {
            //请求密码接口
            this.$confirm('是否修改密码？', '友情提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {

              let params = { userId: this.userId, newPassWord: this.form.newPassWord };

              this.$post(userApi.editPassWord, params).then((res) => {
                if (res.success && res.statusCode == 200) {
                  this.dialogFormVisible = false;
                  this.getMenu();
                }
              });
            }).catch(() => {

            });
          }
        }
      });

    },
    //退出登录
    loginOut() {
      this.$get("/user/loginOut", null).then((res) => {
        if (res.success && res.statusCode == 200) {
          this.$router.push("/");
          let phoneStorage = localStorage.getItem("phone");
          //localStorage.clear();
          localStorage.setItem("phone", phoneStorage);
          localStorage.removeItem("tabsIndexCache");
          localStorage.removeItem("tabsCache");
        }
      });
    },
    clearSession(){
      localStorage.removeItem("tabsIndexCache");
      localStorage.removeItem("tabsCache");
      window.location.reload()
    },
    verifyUser() {

      if (!localStorage.getItem("userId")) {
        window.location.reload();
        this.$router.push("/");
      }
    },
    fristMenuClick(value) {

      this.fristTop = value;
      if (value == "欢迎") {
        this.$router.push("/welcome");
      }


    },
    secendMenuClick(row, fristValue, secendValue) {
      this.fristTop = fristValue;
      this.secendTop = secendValue;
      localStorage.removeItem("menuButtions");
      localStorage.setItem("menuButtions", JSON.stringify(row.childList));
      let url = { path: row.path, query: { meunId: row.id } }
      this.$router.replace(url);
      this.addTab(row); // 添加这一行

    }
  }
};
</script>


<style lang="scss" scoped>
html {
  filter: grayscale(100%)
}

.main {
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;
  background-size: 100% 100%;


  .left {
    height: auto;

    .logo {
      width: 100%;
      color: white;
      font-size: 18px;
      text-align: center;
      height: 60px;
      display: flex;
      align-items: center;
    }
  }

  .right {
    flex: 1;
    height: auto;
    flex-direction: row;

    //顶部导航样式
    .nav {
      width: auto;
      height: 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .nav-left {
        display: flex;
        justify-self: start;

        i {
          background: white;
          font-size: 20px;
        }

        div {
          margin-left: 15px;
          padding: 5px;
          background: white;
          font-size: 15px;
          border-radius: 5px;
        }

      }
    }

    .center {
      overflow-y: auto;
      background-color: white;
      height: 100%;
      padding: 5px;

    }
  }
}

.left-menu .el-menu-item {
  text-align: center;
}

li.el-menu-item.is-active {
  background-color: orange;
  color: #fff !important;
}
</style>
